<!-- footer -->
<template>
<div class='footer'>
  <div class="m-box">
    <div class="footer-box">
      <div class="qr-img">
        <img src="@/assets/img/qr.jpg" alt="">
      </div>
      <div class="font-box">
        <div class="footer-font1">咨询电话：400-617-1118</div>
        <div class="footer-font1">工作时间：周一至周六 9:00-22:00</div>
        <div class="footer-font1">备案号：琼ICP备2022002951号-5</div>
      </div>
      <div class="ba-box">
        <img src="@/assets/img/footer.png" alt="">
        <div class="footer-font2">琼公网安备46010602001641号</div>
      </div>
      <div class="bq-box">版权所有：海南融汇南海科技有限公司</div>
    </div>
  </div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.footer {
  padding: 30px 0;
  border-top: 1px solid #f3f3f3;

  .footer-box {
    width: 750px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .qr-img {
      width: 69px;
      height: 69px;

      img {
        width: 100%;
        height: 100%;
      }

      flex:0 0 69px;
      margin-right:25px;
    }

    .font-box {
      width: 195px;
      flex: 0 0 auto;

      .footer-font1 {
        text-align: left;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-bottom: 10px;
      }
    }

    .ba-box {
      text-align: left;
      width: 200px;
      flex: 0 0 200px;
      display: flex;
      justify-content: start;
      margin-right: 23px;
      padding-top: 51px;

      img {
        width: 18px;
        height: 20px;
        margin-right: 8px;
      }

      .footer-font2 {
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

      }
    }

    .bq-box {
      text-align: left;
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      flex: 0 0 210px;
      padding-top: 51px;
    }
  }
}
</style>