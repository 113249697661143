<template>
  <div id="app">
    <router-view />
    <footer-box></footer-box>
  </div>
</template>

<script>
import footerBox from '@/components/footer.vue';
export default {
  name: 'App',
  components: { footerBox },
  methods: {
    watchWidth () {  //监听屏幕宽度变化
      window.addEventListener("load", adaptation);
      window.addEventListener("resize", adaptation);
      function adaptation () {
        var w = document.body.clientWidth;
        if (w < (1300 + 34)) { // 换成你想出现滚动条的宽度， 34是滚动条宽度
          document.scrollingElement.style.overflowX = ''
        } else {
          document.scrollingElement.style.overflowX = 'hidden'
        }
      }
    },
  },
  created () {
    this.watchWidth()
  }
};
</script>

<style>
@import url('../src/assets/css/reset.scss');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
