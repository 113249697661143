import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../pages/index/index.vue'),
    },
    {
      path: '/index',
      name: 'home',
      component: () => import('../pages/index/index.vue'),
    },
    {
      path: '/product',
      name: 'Product',
      component: () => import('../pages/product/index.vue'),
    },
    {
      path: '/product_item',
      name: 'ProductItem',
      component: () => import('../pages/product_item/details.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('../pages/about/index.vue'),
    },
    {
      path: '/apply',
      name: 'Apply',
      component: () => import('../pages/apply/index.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  next();
});
export default router;
